<template>
  <pcis-steps :form-type="formType" :enable-buttons="false">
    <el-row :gutter="20" class="poster">
      <el-col :lg="8" :sm="10" :xs="24" class="menu">
        <ul class="buttons">
          <li @click="goTo('StepAgreement')">
            <table width="100%">
              <tbody>
                <tr>
                  <td width="20%" align="left">
                    <!-- <font-awesome-icon :icon="['fa', 'file-signature']" class="el-icon" /> -->
                    <el-icon>
                      <edit-pen />
                    </el-icon>
                  </td>
                  <td width="80%" align="left" class="padding-left-20">
                    <h4>{{ $t(`personal.start.buttons.entry.${formType}`) }}</h4>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <el-icon>
              <font-awesome-icon :icon="['far', 'play-circle']" />
            </el-icon>
            <h4>{{ $t("personal.start.buttons.entry") }}</h4> -->
          </li>
          <li @click="goTo('PersonalAppInfo')">
            <table width="100%">
              <tbody>
                <tr>
                  <td width="20%" align="left">
                    <el-icon>
                      <i class="bi bi-info-circle"></i>
                    </el-icon>

                  </td>
                  <td width="80%" align="left" class="padding-left-20">
                    <h4>{{ $t("personal.start.buttons.info") }}</h4>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <el-icon>
              <font-awesome-icon icon="info-circle" />
            </el-icon>
            <h4>{{ $t("personal.start.buttons.info") }}</h4> -->
          </li>
          <li @click="openFAQ">
            <table width="100%">
              <tbody>
                <tr>
                  <td width="20%" align="left">
                    <el-icon>
                      <i class="bi bi-question-circle"></i>
                    </el-icon>

                  </td>
                  <td width="80%" align="left" class="padding-left-20">
                    <h4>{{ $t("personal.start.buttons.faq") }}</h4>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <el-icon>
              <font-awesome-icon :icon="['far', 'question-circle']" />
            </el-icon>
            <h4>{{ $t("personal.start.buttons.faq") }}</h4> -->
          </li>
        </ul>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-alert class="browserSupport" type="info" :closable="false">
          <BrowserSupport type="personal" />
        </el-alert>
      </el-col>
    </el-row>
  </pcis-steps>
</template>

<style scoped>
@media screen and (min-width: 768px) {
  .poster {
    background: url("../../../assets/applicant_start.jpg");
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
    min-height: 50vh;
    margin-top: 35px;
  }

  .buttons>li:last-child {
    margin-bottom: 20px;
  }

  .buttons>li:first-child {
    margin-top: 20px;
  }

  .menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .buttons {
    min-width: 250px;
  }
}
</style>

<script>
import BaseStep from "@/views/personal/steps/BaseStep.vue";
import BrowserSupport from "@/components/BrowserSupport.vue";
import { goTo, openLink } from "../../../libs/common";
import "@/style/common.css"

export default {
  extends: BaseStep,
  name: "PersonalStart",
  component: {
    BrowserSupport
  },
  data() {
    return {};
  },
  // created() {
  //   this.$store.commit("resetPersonalState", false)
  // },
  methods: {
    openFAQ() {
      openLink("personal.faq");
    },
    goTo(name) {
      goTo(name);
    }
  },
  computed: {
    formType() {
      return this.$store.getters.formType
    }
  },
  components: { BrowserSupport }
};
</script>
